import styled from 'styled-components'

import { theme } from 'styles/theme'

const { media, radii, space } = theme

export const VideoPlayer = styled.button`
  position: absolute;
  inset: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: ${space['spacing-xl']};
  height: ${space['spacing-xl']};
  border-radius: ${radii['corner-radius-circular']};
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (${media.desktop}) {
    width: ${space['spacing-xxxl']};
    height: ${space['spacing-xxxl']};
  }
`

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
`
