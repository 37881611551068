import { FC, memo, useState } from 'react'

import NextImage from 'next/image'

import { Play } from './Play'
import * as S from './styles'
import { VideoPlayerProps } from './video-player.model'

export const VideoPlayer: FC<VideoPlayerProps> = memo(
  ({ videoUrl, videoTitle, width, height, imagePlaceholder, alt, priority }) => {
    const [isVideoVisible, setIsVideoVisible] = useState<boolean>(false)

    return (
      <>
        {isVideoVisible ? (
          <S.VideoWrapper>
            <div className="embed-container">
              <iframe
                src={videoUrl}
                title={videoTitle}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </S.VideoWrapper>
        ) : (
          <S.ImageWrapper>
            <NextImage
              src={imagePlaceholder}
              alt={alt}
              width={width}
              height={height}
              quality="100"
              priority={priority}
              sizes="100vw"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '1rem'
              }}
            />

            <S.VideoPlayer onClick={() => setIsVideoVisible(oldState => !oldState)} aria-label="Iniciar vídeo">
              <Play />
            </S.VideoPlayer>
          </S.ImageWrapper>
        )}
      </>
    )
  }
)

VideoPlayer.displayName = 'Video Player'
