import styled from 'styled-components'

import { theme } from 'styles/theme'

const { media, space, colors, radii } = theme

export const ForYou = styled.div`
  width: 100%;
  padding: ${space['spacing-xl']} 0 ${space['spacing-xl']} 0;
  background-color: ${colors['neutral-background-contrast']};

  & > div > div:nth-child(2) {
    p {
      margin-bottom: ${space['spacing-sm']};
    }
  }

  h2 > span,
  h2 {
    font-size: ${space['spacing-md']};
    text-align: start;
    padding-bottom: ${space['spacing-md']};
    width: max-content;
  }

  @media (${media.tablet}) {
    padding: ${space['spacing-giga']} 0 7.5rem 0;

    & > div > div:nth-child(2) {
      order: -1;

      p {
        margin-bottom: ${space['spacing-sm']};
        width: 85%;
      }
    }

    h2 > span,
    h2 {
      font-size: ${space['spacing-xxxl']};
      padding-bottom: ${space['spacing-lg']};
    }
  }
`

export const DivTextsList = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: space-between;
  gap: ${space['spacing-sm']};
  padding-bottom: ${space['spacing-md']};

  li {
    list-style-type: disc;
    color: ${colors['primary-icon-strong']};
    margin-left: ${space['spacing-md']};
  }

  @media (${media.tablet}) {
    padding-bottom: 0;
  }
`

export const TitleList = styled.div`
  display: flex;
  flex-direction: columns;
  align-items: center;
  gap: ${space['spacing-sm']};
`

export const IconWrapper = styled.div`
  display: flex;
  gap: ${space['spacing-sm']};
  align-items: center;

  & > span {
    background-color: ${colors['neutral-background-medium']};
    width: 40px;
    height: 40px;
    padding: ${space['spacing-xxs']};
    border-radius: ${radii['corner-radius-lg']};
  }

  @media (${media.tablet}){
    align-items: normal;
    flex-direction: column;
  }
`

export const DivList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-sm']};

  @media (${media.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${space['spacing-mega']};
  }
`

export const Video = styled.div`
  position: relative;
  margin-bottom: ${space['spacing-lg']};
  border-radius: ${radii['corner-radius-md']};
  overflow: hidden;

  @media (${media.tablet}) {
    margin-right: ${space['spacing-lg']};
    margin-top: 6rem;
  }
`
