import { memo } from 'react'

export const Play = memo(() => (
  <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27.2604" cy="26.8434" r="25.5444" stroke="#E6E8EB" strokeWidth="2.57843" />
    <path
      d="M23.3903 39.4224L39.6917 30.1984C40.8438 29.5465 41.556 28.3251 41.556 27.0013C41.556 25.6775 40.8438 24.4561 39.6917 23.8042L23.3903 14.5802C22.8902 14.2972 22.3253 14.1484 21.7506 14.1484C19.9116 14.1484 18.4209 15.6392 18.4209 17.4781V36.5245C18.4209 38.3634 19.9116 39.8541 21.7506 39.8541C22.3253 39.8541 22.8902 39.7054 23.3903 39.4224Z"
      stroke="white"
      strokeWidth="2.57843"
    />
  </svg>
))

Play.displayName = 'Play'
