import { FC, memo } from 'react'

import { Text, Icon } from '@cash-me/react-components'

import { Grid, Col } from 'components/grid'
import { Polygon } from 'components/polygon'
import { VideoPlayer } from 'components/video-player'

import * as S from './styles'

const ForYou: FC = memo(() => (
  <>
    <S.ForYou>
      <Grid col={{ phone: 1, tablet: 12, desktop: 12 }}>
        <Col col={{ tablet: 5, desktop: 5 }} start={{ tablet: 7, desktop: 7 }}>
          <S.Video>
            <VideoPlayer
              videoUrl="https://www.youtube-nocookie.com/embed/97tljzLPwJk?controls=0&autoplay=1"
              videoTitle="Empréstimo com Garantia de Imóvel vale a pena?"
              width={535}
              height={294}
              imagePlaceholder="/images/consortium/video-consortium.png"
              alt="Mulher da Cashme explicando sobre o empréstimo com garantia"
            />
          </S.Video>
        </Col>
        <Col col={{ tablet: 5, desktop: 5 }} start={{ tablet: 2, desktop: 2 }}>
          <Text as="h2" size="heading2" weight="medium" color="var(--colors-pink-text-contrast)">
            Consórcio{' '}
            <Text as="span" size="heading2" weight="medium" color="var(--colors-pink-text-strong)">
              é para você?
            </Text>
          </Text>
          <Text color="var(--colors-pink-text-strong)">
            O consórcio é um tipo de investimento planejado para adquirir bens sem comprometer sua saúde financeira.
            Funciona através da formação de grupos de pessoas com objetivos comuns, que contribuem mensalmente para um
            fundo comum. A contemplação acontece por meio de sorteios ou lances, garantindo que todos possam conquistar
            seu bem até o final do plano.{' '}
          </Text>
          <Text color="var(--colors-pink-text-strong)">
            Parece uma solução inteligente, não é? Mas será que é a melhor opção para você? Confira!
          </Text>
          <Text color="var(--colors-pink-text-strong)" weight="bold">
            É opção para:
          </Text>
        </Col>
        <Col col={{ tablet: 10, desktop: 10 }} start={{ tablet: 2, desktop: 2 }}>
          <S.DivList>
            <S.DivTextsList>
              <S.IconWrapper>
                <Icon name="piggyBank" size={28} color="var(--colors-primary-icon-default)" />
                <Text as="h3" color="var(--colors-pink-text-strong)" size="heading4" weight="medium">
                  Poupança
                </Text>
              </S.IconWrapper>
              <Text color="var(--colors-pink-text-strong)">
                Para quem busca uma forma de ter disciplina para conquistar objetivos.{' '}
              </Text>
              <S.TitleList>
                <Polygon
                  type="Triangle"
                  width={10}
                  height={12}
                  draw="fill"
                  color="pink"
                  strokeWidth="1"
                  className="pink-polygon"
                />
                <Text color="var(--colors-pink-text-strong)">
                  Usos comuns:
                </Text>
              </S.TitleList>
              <ul>
                <li>
                  <Text color="var(--colors-pink-text-strong)">Bens para filhos</Text>
                </li>
                <li>
                  <Text color="var(--colors-pink-text-strong)">Sonho da casa própria</Text>
                </li>
                <li>
                  <Text color="var(--colors-pink-text-strong)">Primeiro carro</Text>
                </li>
              </ul>
            </S.DivTextsList>
            <S.DivTextsList>
              <S.IconWrapper>
                <Icon name="garage" size={28} color="var(--colors-primary-icon-default)" />
                <Text as="h3" color="var(--colors-pink-text-strong)" size="heading4" weight="medium">
                  Aquisição
                </Text>
              </S.IconWrapper>
              <Text color="var(--colors-pink-text-strong)">
                Para quem quer conquistar objetivos com as melhores condições.{' '}
              </Text>
              <S.TitleList>
                <Polygon
                  type="Triangle"
                  width={10}
                  height={12}
                  draw="fill"
                  color="pink"
                  strokeWidth="1"
                  className="pink-polygon"
                />
                <Text color="var(--colors-pink-text-strong)">
                  Usos comuns:
                </Text>
              </S.TitleList>
              <ul>
                <li>
                  <Text color="var(--colors-pink-text-strong)">Troca de carro/frota</Text>
                </li>
                <li>
                  <Text color="var(--colors-pink-text-strong)">Imóvel na planta</Text>
                </li>
                <li>
                  <Text color="var(--colors-pink-text-strong)">Quitação de financiamento</Text>
                </li>
              </ul>
            </S.DivTextsList>
            <S.DivTextsList>
              <S.IconWrapper>
                <Icon name="moneyBag" size={28} color="var(--colors-primary-icon-default)" />
                <Text as="h3" color="var(--colors-pink-text-strong)" size="heading4" weight="medium">
                  Investimento
                </Text>
              </S.IconWrapper>
              <Text color="var(--colors-pink-text-strong)">
                Para quem quer aproveitar as vantagens de investir com baixo risco.{' '}
              </Text>
              <S.TitleList>
                <Polygon
                  type="Triangle"
                  width={10}
                  height={12}
                  draw="fill"
                  color="pink"
                  strokeWidth="1"
                  className="pink-polygon"
                />
                <Text color="var(--colors-pink-text-strong)">
                  Usos comuns:
                </Text>
              </S.TitleList>
              <ul>
                <li>
                  <Text color="var(--colors-pink-text-strong)">Revenda da cota</Text>
                </li>
                <li>
                  <Text color="var(--colors-pink-text-strong)">Imóveis para locação</Text>
                </li>
                <li>
                  <Text color="var(--colors-pink-text-strong)">Construção e revenda</Text>
                </li>
              </ul>
            </S.DivTextsList>
          </S.DivList>
        </Col>
      </Grid>
    </S.ForYou>
  </>
))

ForYou.displayName = 'ForYou'

export default ForYou
